import { COLORS } from "src/constants/theme";
import Swal from "sweetalert2";

export const mostrarMensajeExito = (titulo, descripcion) => {
  return Swal.fire({
    icon: "success",
    title: titulo,
    text: descripcion,
    confirmButtonColor: "#e55353",
  });
};

export const mostrarMensajeError = (titulo, descripcion) => {
  return Swal.fire({
    icon: "error",
    title: titulo,
    text: descripcion,
    confirmButtonColor: "#e55353",
  });
};

export const mostrarMensajePregunta = (
  title,
  descripcion,
  confirmButtonText = "Aceptar",
  cancelButtonText = "Cancelar",
  showCloseButton = false
) => {
  return Swal.fire({
    title: title,
    text: descripcion,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: COLORS.primary,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    allowOutsideClick: false,
    showCloseButton: showCloseButton,
  });
};
