import { initializeApp } from "firebase/app";
import { initializeAuth, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyCGOuhpM7GYQiDYRUrVm82YiJTdlvpziEw",
  authDomain: "sorteos-herradura-roja.firebaseapp.com",
  projectId: "sorteos-herradura-roja",
  storageBucket: "sorteos-herradura-roja.appspot.com",
  messagingSenderId: "629384436563",
  appId: "1:629384436563:web:a913fcf927330692681fa6",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
});
export const db = getFirestore(firebaseApp);

export const functions = getFunctions(firebaseApp);

// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// export const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider("6LdwYOApAAAAALc8zpLvfmpgMkX48ufESofYfdjm"),
//   isTokenAutoRefreshEnabled: true,
// });
