import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import {
  cibWhatsapp,
  cilCart,
  cilChevronTop,
  cilChevronBottom,
} from "@coreui/icons";
import { enviarMensajeWhatsApp } from "src/utils/Utils";
import { COLORS } from "src/constants/theme";
import { size } from "lodash";
import { mostrarMensajeError } from "src/utils/Mensajes";

const WhatsAppButton = ({
  phone,
  mensaje = "",
  selectedTickets,
  handleBuyTickets,
  sorteoDisabled,
}) => {
  const [visible, setVisible] = useState(true);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handleComprarBoletos = () => {
    if (sorteoDisabled) {
      mostrarMensajeError(
        "Compra de Boletos No Disponible",
        "En este momento, la compra de boletos no está disponible."
      );
    } else {
      handleBuyTickets();
    }
  };

  return (
    <>
      <div
        style={{ ...styles.button, ...styles.toggleButton }}
        onClick={toggleVisibility}
      >
        <CIcon
          icon={visible ? cilChevronTop : cilChevronBottom}
          size="xl"
          style={styles.toggleIcon}
        />
      </div>
      <div
        style={{
          ...styles.buttonContainer,
          ...(!visible && styles.hidden),
        }}
      >
        {size(selectedTickets) > 0 && (
          <div
            style={{
              ...styles.button,
              ...styles.cartButton,
              position: "relative",
            }}
            onClick={handleComprarBoletos}
          >
            <CIcon icon={cilCart} size="xxl" style={styles.icon} />
            <div className="numeros" style={styles.cartItemCount}>
              {size(selectedTickets)}
            </div>
          </div>
        )}
        <div
          style={{ ...styles.button, ...styles.whatsappButton }}
          onClick={() => enviarMensajeWhatsApp(phone, mensaje)}
        >
          <CIcon icon={cibWhatsapp} size="xxl" style={styles.icon} />
        </div>
      </div>
    </>
  );
};

const styles = {
  buttonContainer: {
    position: "fixed",
    bottom: "90px",
    right: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transition: "transform 0.3s ease, opacity 0.3s ease",
    zIndex: 1001,
  },
  hidden: {
    transform: "translateY(20px)",
    opacity: 0,
    visibility: "hidden",
  },
  button: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
    cursor: "pointer",
    marginBottom: "10px",
    transition: "transform 0.3s ease, opacity 0.3s ease",
    zIndex: 1002,
    position: "relative",
  },
  toggleButton: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#ffffff",
    zIndex: 1003,
  },
  whatsappButton: {
    backgroundColor: COLORS.success,
  },
  cartButton: {
    backgroundColor: COLORS.primary,
  },
  icon: {
    color: "white",
  },
  toggleIcon: {
    color: COLORS.gray,
  },
  cartItemCount: {
    position: "absolute",
    top: "-5px",
    right: "-4px",
    width: "25px",
    height: "25px",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.primary,
    fontWeight: "bold",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
  },
};

export default WhatsAppButton;
