import React, { useEffect, useState } from "react";
import { CSpinner } from "@coreui/react";
import logo from "src/assets/brand/LogotipoOficial.png";
import "src/scss/_custom.scss";

const BlockedUserScreen = () => {
  const [imageUp, setImageUp] = useState(true);

  useEffect(() => {
    const toggleImagePosition = () => {
      setImageUp((prevImageUp) => !prevImageUp);
    };

    const interval = setInterval(toggleImagePosition, 500);

    toggleImagePosition();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };

  const spinnerStyle = {
    marginTop: "20px",
  };

  const sombraStyle = {
    width: imageUp ? "60px" : "100px",
    background: imageUp ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.3)",
  };

  const imagenStyle = {
    transform: imageUp ? "translateY(-20px)" : "translateY(0)",
  };

  return (
    <div style={containerStyle}>
      <div className="contenedor-imagen">
        <div className="sombra" style={sombraStyle}></div>
        <img src={logo} alt="Logo" className="imagen" style={imagenStyle} />
      </div>
      <CSpinner color="primary" style={spinnerStyle} />
      <div style={{ textAlign: "center", margin: "10px" }}>
        <h2 className="titulos mt-2">Usuario Bloqueado</h2>
        <p className="textos">
          Por favor, póngase en contacto con el soporte de Sorteos Herradura
          Roja.
        </p>
      </div>
    </div>
  );
};

export default BlockedUserScreen;
