import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
// import LoadingHome from "./views/pages/loadingHome/LoadingHome";
// import { isEmbeddedBrowser } from "./utils/Utils";

const RootComponent = () => {
  // useEffect(() => {
  //   if (isEmbeddedBrowser()) {
  //     alert(
  //       "Para continuar, por favor abre la siguiente URL en tu navegador externo: https://sorteosherraduraroja.com"
  //     );
  //   }
  // }, []);

  // if (isEmbeddedBrowser()) {
  //   return (
  //     <div style={{ textAlign: "center", padding: "20px" }}>
  //       <LoadingHome isLoading={false} />
  //     </div>
  //   );
  // }

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

createRoot(document.getElementById("root")).render(<RootComponent />);

reportWebVitals();
