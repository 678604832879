import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import logo from "src/assets/brand/LogotipoOficial_FondoBlanco.png";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "src/utils/Utils";
import { sendEmailResetPassword } from "src/utils/Acciones";
import { mostrarMensajeError, mostrarMensajeExito } from "src/utils/Mensajes";
import { COLORS } from "src/constants/theme";
import HelmetMetaData from "src/components/core/HelmetMetData";

const RecuperarContraseña = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    if (!validateEmail(email)) {
      mostrarMensajeError(
        "Error",
        "El correo electrónico ingresado no es válido"
      );
      return;
    }

    try {
      const { statusresponse, error } = await sendEmailResetPassword(email);
      if (statusresponse) {
        mostrarMensajeExito(
          "Éxito",
          "Se ha enviado un correo electrónico para restablecer tu contraseña"
        ).then(() => {
          navigate("/login");
        });
      } else {
        mostrarMensajeError(
          "Error",
          `Error al enviar el correo electrónico: ${error}`
        );
      }
    } catch (error) {
      mostrarMensajeError(
        "Error",
        `Error al enviar el correo electrónico: ${error.message}`
      );
    }
  };

  return (
    <>
      <HelmetMetaData title="Restablecer Contraseña - Sorteos Herradura Roja" />
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h2 className="titulos">Contraseña</h2>
                      <p className="text-medium-emphasis textos">
                        Reestablecer Contraseña
                      </p>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>@</CInputGroupText>
                        <CFormInput
                          type="email"
                          placeholder="Ingresa tu correo electrónico"
                          autoComplete="current-email"
                          value={email}
                          className="textos"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </CInputGroup>
                      <CButton
                        style={{
                          backgroundColor: COLORS.secondary,
                          borderColor: COLORS.secondary,
                          color: COLORS.brown,
                        }}
                        className="px-4 subtitulos"
                        onClick={handleResetPassword}
                      >
                        Enviar correo electrónico
                      </CButton>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard
                  className="text-white py-5"
                  style={{ width: "44%", backgroundColor: COLORS.primary }}
                >
                  <CCardBody className="text-center">
                    <div>
                      <img src={logo} alt="Logo" height={150} />
                      <p style={{ marginTop: "20px" }} className="textos">
                        {" "}
                        &copy; 2024 Sorteos Herradura Roja{" "}
                      </p>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  );
};

export default RecuperarContraseña;
