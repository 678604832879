import React from "react";
import { Helmet } from "react-helmet";

export default function HelmetMetaData(props) {
  const {
    url,
    quote = "",
    title = "Sorteos Herradura Roja",
    image = "https://firebasestorage.googleapis.com/v0/b/sorteos-herradura-roja.appspot.com/o/SORTEOS_HERRADURA_ROJA%2FLogo.png?alt=media&token=5d7e1b33-778c-4e85-a6b9-0112744a330e",
    description = "¡Únete a la estampida de la suerte. Sorteos Herradura Roja!",
    hashtag = "#sorteosherraduraroja",
  } = props;

  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content={description} />

      {/* Open Graph Tags */}
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Sorteos Herradura Roja" />
      <meta property="og:image" content={image} />
      <meta property="og:image:type" content="image/png" />

      {/* Twitter Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={title} />

      {/* Additional Tags */}
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
    </Helmet>
  );
}
