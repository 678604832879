import React from "react";
import "../styles/loadingProcess.css";

const LoadingProcess = ({ isLoading }) => {
  return isLoading ? (
    <div className="loading-overlay">
      <div className="loading-spinner"></div>
    </div>
  ) : null;
};

export default LoadingProcess;
