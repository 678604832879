export const COLORS = {
  primary: "#e1251b",
  secondary: "#fde576",
  black: "#212427",
  white: "#FFFFFF",
  light: "#ebedef",
  success: "#2eb85c",
  info: "#3399ff",
  warning: "#f9b115",
  brown: "#8e481f",
  gray: "#808080",
};

export const TAMAÑOS = {
  margincontainer: 20,
  radiusboton: 10,
};

const Fuentes = { COLORS, TAMAÑOS };

export default Fuentes;
